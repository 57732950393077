import { truncate } from 'lodash-es'

export const isTopInViewport = (element: Element): boolean => {
  const rect = element.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.right <= (window.innerWidth ?? document.documentElement.clientWidth)
  )
}

export const appendQueryToPath = (path: string, newQueryObject: Record<string, string>): string => {
  const url = new URL(path, location.href)
  const originalSource = path.split('?')[0]
  const originalQueryObj = Object.fromEntries(url.searchParams)
  const fullQueryObj = { ...originalQueryObj, ...newQueryObject }
  const queryString = new URLSearchParams(fullQueryObj).toString()
  return `${originalSource}?${queryString}`
}

export const truncateString = (string: string, length: number): string => {
  return truncate(string, {
    length,
    separator: ' ',
    omission: '...'
  })
}
